import React from 'react'
import WindowsIcon from '../assets/Windows.svg'
import MacIcon from '../assets/Apple.svg'
import { Card } from 'react-bootstrap'
import Style from './deployment.module.css'

const DeploymentAutomatic = ({ msiUrl, licenseKey, currentSkin, supportEmailDeployment }) => {
  return (
    <>
      <div>
        <div className={`mt-4`}>
          <div className={`${Style.titleStyle}`}>Automatic Deployment</div>
        </div>
        <p className={`${Style.textStyle} w-50 w-sm-100 mb-0`}>
          The below device installers will automatically install and configure Pixm on Chrome, Edge, and Firefox with your private threat feed and with device attribution.<br /> They contain your organization's unique license key and should not be shared with anyone outside your organization or security team
        </p>
        <div className="d-flex w-100 flex-xs-column flex-sm-column flex-md-column flex-lg-row flex-xl-row">
          <Card
            bg={currentSkin}
            text={currentSkin === 'light' ? 'black' : 'white'}
            className={
              'shadow rounded-lg  flex-fill my-4  w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50'
            }
            style={{ marginRight: '1.4%' }}
          >
            <Card.Body style={{ padding: '26px 27px 28px 28px' }}>
              {/* =============== Windows Section ===================== */}
              <div
                className={`${Style.subTitleStyle}`}
                style={{ marginBottom: '19px' }}
              >
                Installers
              </div>
              <div className="text-dark" style={{ marginBottom: '9px' }}>
                <b
                  className={`${Style.subHeaderFontStyle}`}
                  style={{
                    color: '#333',
                  }}
                >
                  Platform
                </b>
              </div>
              <div
                text={currentSkin === 'light' ? 'black' : 'white'}
                className={`${Style.downloadSection} rounded-lg d-flex flex-row`}
              >
                <div
                  className={`${Style.downloadSectionImageIcon} d-flex my-auto`}
                >
                  <img
                    style={{ width: '46px' }}
                    src={WindowsIcon}
                    alt="windows-icon"
                  />
                </div>
                <div
                  className={`d-flex align-items-center justify-content-start ${Style.downloadSectionText} ${Style.textStyle}`}
                >
                  Download for Windows
                </div>
                <div
                  className={`${Style.downloadSectionButton} d-flex justify-content-end`}
                >
                  {msiUrl && (
                    <a
                      href={msiUrl}
                      className={`btn btn-primary py-3 px-4 d-flex align-center ${Style.downloadButton} ${Style.downloadButtonActive} `}
                      download="PIXM_WINDOWS_INSTALLER"
                    >
                      <b className={`${Style.subHeaderFontStyle}`}>
                        Download Installer
                      </b>
                    </a>
                  )}
                </div>
              </div>

              {/* =============== Mac-OSX Section ===================== */}
              <div
                text={currentSkin === 'light' ? 'black' : 'white'}
                className={`${Style.downloadSection} rounded-lg d-flex flex-row`}
              >
                <div
                  className={`${Style.downloadSectionImageIcon} d-flex my-auto`}
                >
                  <img
                    style={{ width: '46px' }}
                    src={MacIcon}
                    alt="windows-icon"
                  />
                </div>
                <div
                  className={`d-flex align-items-center justify-content-start ${Style.downloadSectionText} ${Style.textStyle}`}
                >
                  Download for Mac OSX
                </div>
                <div
                  className={`${Style.downloadSectionButton} d-flex justify-content-end`}
                >
                  <a
                    // href="#"
                    className={`py-3 d-flex align-center ${Style.downloadButton}`} style={{textDecoration:'none'}}
                    disabled
                  >
                    <b className={`${Style.subHeaderFontStyle}`}>
                      <span style={{color:"black", fontWeight:'100'}}> contact</span> {supportEmailDeployment ? supportEmailDeployment : 'support@pixm.net'}
                    </b>
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card
            bg={currentSkin}
            text={currentSkin === 'light' ? 'black' : 'white'}
            className={
              'shadow rounded-lg  flex-fill my-4 w-xs-100 w-sm-100 w-md-100 w-lg-50 w-xl-50 h-200'
            }
            style={{height: "186px"}}
          >
            <div style={{ padding: '26px 27px 28px 28px' }}>
              <div
                className={`${Style.subTitleStyle}`}
                style={{ marginBottom: '19px' }}
              >
                Deployment Guides
              </div>
              <div
                text={currentSkin === 'light' ? 'black' : 'white'}
                style={{ padding: '0px 9px 0px 10px', marginBottom: '9px' }}
                className={`rounded-lg d-flex flex-row`}
              >
                <div
                  className={`${Style.downloadSectionMethod} text-dark d-flex my-auto`}
                >
                  <b
                    className={`${Style.subHeaderFontStyle}`}
                    style={{
                      color: '#333',
                    }}
                  >
                    Method
                  </b>
                </div>
                <div
                  className={`d-flex align-items-center justify-content-start ${Style.downloadSectionText}`}
                >
                  <b
                    className={`${Style.subHeaderFontStyle}`}
                    style={{
                      color: '#333',
                    }}
                  >
                    Description
                  </b>
                </div>
                <div
                  className={`${Style.downloadSectionButton} d-flex justify-content-end`}
                ></div>
              </div>
              {/* =============== GPO =================== */}
              <div
                text={currentSkin === 'light' ? 'black' : 'white'}
                className={`${Style.downloadSection} rounded-lg d-flex flex-row mb-0`}
              >
                <div
                  className={`${Style.downloadSectionMethod} ${Style.textStyle} text-dark d-flex my-auto`}
                >
                  GPO
                </div>
                <div
                  className={`d-flex align-items-center justify-content-start ${Style.downloadSectionText} ${Style.textStyle}`}
                >
                  Instructions for deploying Pixm with GPO
                </div>
                <div
                  className={`${Style.downloadSectionButton} d-flex justify-content-end`}
                >
                  <a
                    href={
                      'https://external.pixm.net/install-instructions/Pixm%20GPO%20Instrctions.pdf'
                    }
                    className={`btn btn-primary py-3 px-4 d-flex align-center ${Style.downloadButton} ${Style.downloadButtonActive} `}
                    download="GPO_PIXM_PDF"
                  >
                    <b className={`${Style.subHeaderFontStyle}`}>
                      Download PDF
                    </b>
                  </a>
                </div>
              </div>              
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default DeploymentAutomatic
