import React from 'react';
import { useState, useEffect } from 'react';
import Style from './AddEditBrandPolicies.module.css';
import { connect } from 'react-redux'
import { addBrandPolicy, updateBrandPolicy, clearBrandPolicyError } from '../store/entities/customers';

const AddEditBrandPolicies = (props) => {
    let { brandPolicy } = props.location.state;
    const [brandName, setBrandName] = useState(brandPolicy ? brandPolicy.brandName !== null ? brandPolicy.brandName : '' : '');
    const [isActive, setIsActive] = useState(brandPolicy ? brandPolicy.isActive !== null ? brandPolicy.isActive : true : true);
    const [brandAlias, setBrandAlias] = useState(brandPolicy ? brandPolicy.alias !== null ? brandPolicy.alias : [""] : [""]);
    const [brandDomain, setBrandDomain] = useState(brandPolicy ? brandPolicy.domain !== null ? brandPolicy.domain : [""] : [""]);
    const [errors, setErrors] = useState([]);
    let mode = props.location.state.mode ? props.location.state.mode : 'add';


    useEffect(() => {
        setErrors([]);
        props.clearBrandPolicyError();
    }, []);

    const handleBrandNameChange = (e) => {
        setBrandName(e.target.value);
    }

    const handleIsActiveChange = (e) => {
        setIsActive(e.target.checked);
    }

    const handleSave = () => {
        let errors = validateInputs();
        setErrors(errors);
        props.clearBrandPolicyError();
        if(errors.length === 0){
            if(mode === 'add'){
                props.addBrandPolicy({
                    brandName: brandName,
                    isActive: isActive,
                    alias: brandAlias,
                    domains: brandDomain
                });
            }
            else if(mode === 'edit'){
                props.updateBrandPolicy(brandPolicy.id, {
                    brandName: brandName,
                    isActive: isActive,
                    alias: brandAlias,
                    domains: brandDomain
                });
         
           }
           else{
            console.warn("Invalid mode");
           }
        }
        else{
            console.warn("Invalid inputs");
        }
        
    }

    const validateInputs = () => {
        const domainRegex = /^(?!-)([A-Za-z0-9-]{1,}\.)+[A-Za-z]{2,}$/;
        const errors = [];
        if(brandName === ''){
            errors.push('Brand Name is required');
        }

        if(brandName.length > 100){
            errors.push('Brand Name cannot exceed 100 characters');
        }

        if(brandAlias.length === 0){
            errors.push('At least one Brand Alias is required');
        }
        if(brandDomain.length === 0){
            errors.push('At least one Brand Domain is required');
        }

        if(brandAlias.length > 0 && brandAlias.includes('')){
            errors.push('Brand Alias cannot be empty');
        }

        if(brandAlias.length > 0){
            for(let i = 0; i < brandAlias.length; i++){
                if(brandAlias[i].length > 100){
                    errors.push('Brand Alias cannot exceed 100 characters');
                    break;
                }
            }
        }

        if(brandDomain.length > 0){
            if(brandDomain.includes(''))
                errors.push('Brand Domain cannot be empty');

            for(let i = 0; i < brandDomain.length; i++){
                console.log("Validating brand domain..",brandDomain[i]);
                if(!domainRegex.test(brandDomain[i])){
                    errors.push('Invalid Domain');
                    break;
                }
                if(brandDomain[i].length > 200){
                    errors.push('Brand Domain cannot exceed 200 characters');
                    break;
                }
            }
        }

        return errors;
        
    }

    const handleInputChange = (index, value, type) => {
        if(type === 'brandAlias'){
            const updatedFields = [...brandAlias];
            updatedFields[index] = value; // Update the value at the specific index
            setBrandAlias(updatedFields);
        }
        else if(type === 'brandDomain'){
            const updatedFields = [...brandDomain];
            updatedFields[index] = value; // Update the value at the specific index
            setBrandDomain(updatedFields);

        }
        else{
            console.error("Invalid type");
        }
    };

    const addInputField = (data, setData) => {
        setData([...data, ""]); // Add a new empty string to the array
    };

    const removeInputField = (index, data, setData ) => {
        const updatedFields = [...data];
        updatedFields.splice(index, 1); // Remove the input field at the specific index
        setData(updatedFields);
    }

    const handleBack = () => {
        props.history.push({pathname: '/advanced-settings', state: {tab: 'Baseline Brand Policies'}});
    }

    return (
        <>
            <div className={'px-5 mt-4'}>
                <div className={`w-100 mt-2 py-2`}>
                    <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-2`}>
                        <div style={{width: '80%'}} className={`${Style.borderStyle} px-4`}>
                            <div className={`${Style.title}`}>
                                Add/Update Brand Policy
                            </div>
                            <div className={`${Style.currentAdminCount}`}>
                                <input name='brandName' placeholder={'Enter Brand Name'} className={`${Style.inputStyle} focus-none mt-3`} onChange={handleBrandNameChange} value={brandName} />
                                <span className='px-2'>Active</span><input type='checkbox' className='px-2' onChange={handleIsActiveChange} checked={isActive} />
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className={`${Style.detailsContainer} w-100 mt-2 py-2 px-2`}>
                    <div className={`${Style.inputGroup} mt-3 px-3`}>
                        <span className={`${Style.title}`}>Brand Alias</span>
                        <div className={`${Style.border} mt-2 mh-30`} />
                        {brandAlias.map((aliasInputField, index) => {
                            return (
                                <div className={`mt-2`} key={`brndalias_ip_grp${index}`}>
                                    <input
                                        key={`alias_ip_${index}`}
                                        value={aliasInputField}
                                        onChange={(e) => handleInputChange(index, e.target.value,'brandAlias')}
                                        className={`${Style.inputStyle}`}
                                        placeholder='Enter Alias'
                                    />

                                    <button
                                        key={`alias_btn_${index}`}
                                        onClick={() => removeInputField(index, brandAlias, setBrandAlias)}
                                        className={`${Style.cancelButton} ml-1`}
                                    >✕ Delete
                                    </button>
                                </div>
                            )
                        })}
                        
                    </div>
                    <div className={'mt-2 px-3'}>
                            <button onClick={() => addInputField(brandAlias, setBrandAlias)} className={`${Style.addButton}`}>+ Add New Alias</button>
                    </div>

                    <div className={`${Style.inputGroup} mt-3 px-3`}>
                        <span className={`${Style.title}`}>Brand Domain</span>
                        <div className={`${Style.border} mt-2 mh-30`} />
                        {brandDomain.map((domainInputField, index) => {
                            return (
                                <div className={`mt-2`} key={`brnddmn_ip_grp${index}`}>
                                    <input
                                        key={`domain_ip_${index}`}
                                        value={domainInputField}
                                        onChange={(e) => handleInputChange(index, e.target.value, 'brandDomain')}
                                        className={`${Style.inputStyle}`}
                                        placeholder='Enter Domain'
                                    />

                                    <button
                                        key={`domain_btn_${index}`}
                                        onClick={() => removeInputField(index, brandDomain, setBrandDomain)}
                                        className={`${Style.cancelButton} ml-1`}
                                    >✕ Delete
                                    </button>
                                </div>
                            )
                        })}
                        
                    </div>
                    <div className={'mt-2 px-3'}>
                            <button onClick={() => addInputField(brandDomain, setBrandDomain)} className={`${Style.addButton}`}>+ Add New Domain</button>
                    </div>

                    <span id="msg_box" className='mt-3 px-3'></span>
                    <div className={'mt-4 px-3'}>
                        <button onClick={handleSave} className={`${Style.submitButton} px-4 focus-none`}>Save</button>
                        <button onClick={handleBack} className={`${Style.deleteButton} px-4 ml-2 focus-none`}>Close</button>
                        <span className={`${Style.errorMsg} px-2`} id="msg">{errors.join(", ")}</span>
                        <span className={`${ props.addEditBPError ? Style.errorMsg  : Style.successMsg} px-2`} id="msg">{props.addEditBrandPolicyMsg}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    addEditBrandPolicyMsg : state.entities.customers.addEditBrandPolicyMsg,
    addEditBPError : state.entities.customers.addEditBPError,
})

const mapDispatchToProps = (dispatch) => ({
    addBrandPolicy : (body) => dispatch(addBrandPolicy(body)),
    updateBrandPolicy : (Id, body) => dispatch(updateBrandPolicy(Id, body)),
    clearBrandPolicyError : () => dispatch(clearBrandPolicyError())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBrandPolicies)