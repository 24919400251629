import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../api'
import { lookupInitialState as initialState } from '../initialState/lookupState'


const slice = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    lookupDataReceived: (lookup, action) => {
        let lookupResponse = action.payload.response;
        lookup.loading = false;
        if(lookupResponse.errors.lenght > 0){
            lookup.errorMessage = lookupResponse.errors[0].desc;
        }
        else {
            lookupResponse.data.forEach((element) => {
            lookup.data.push(element);
            });
        }
      },
    lookupDataRequestFailed: (lookup, action) => {
        lookup.errorMessage = "Lookup data request failed";
        lookup.loading = false;
      },
    lookupDataRequested: (lookup, action) => {
        lookup.loading = true;
      }
    }
})

export const { 
    lookupDataReceived,
    lookupDataRequested,
    lookupDataRequestFailed
 } = slice.actions

export default slice.reducer

export const getLookupData = () => {
    return apiCallBegan({
      urlPath: `/lookup/LKP_SRVC_TYPE`,
      method: 'GET',
      onStart: lookupDataRequested.type,
      onSuccess: lookupDataReceived.type,
      onError: lookupDataRequestFailed.type,
    })
  }