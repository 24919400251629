import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Card, Form } from 'react-bootstrap';
import Style from './AdvancedSettings.module.css';
import React from 'react';
import { 
  getMultimodalSettings, 
  updateMultimodalSettings,
  getDemoModeSettings,
  updateDemoModeSettings,
  getProcessPageSettings,
  updateProcessPageSettings } from '../store/entities/customers';
import { getLookupData } from '../store/entities/lookup'

import LoaderWrapper from "./common/LoaderWrapper/LoaderWrapper";

const ScanSettings = (props) => {

  const [mmEnabled, setMmEnabled] = useState(props.mmEnabled);
  const [modelCode, setmodelCode] = useState(props.modelCode);
  const [demomodeEnabled, setDemomodeEnabled] = useState(props.demomodeEnabled);
  const [ppEnabled, setProcessPageEnabled] = useState(props.ppEnabled);

  useEffect(() => {
    props.getLookupData();
    props.getMultimodalSettings(props.selectedCustomer?.id);
    props.getDemoModeSettings(props.selectedCustomer?.id);
    props.getProcessPageSettings(props.selectedCustomer?.id);
  }, [props.selectedCustomer?.id])

  useEffect(() => {
    setMmEnabled(props.mmEnabled);
    setmodelCode(props.modelCode);
    setDemomodeEnabled(props.demomodeEnabled);
    setProcessPageEnabled(props.ppEnabled);
  }, [props.mmEnabled, props.modelCode, props.demomodeEnabled, props.ppEnabled])

  const handleOnmmEnabledChange = (e) => {
    setMmEnabled(e.target.checked);
    if(e.target.checked){
      setmodelCode(props.lookupData.filter(model => model.code === 'OPENAI1')[0].code);
    }
    else{
      setmodelCode(props.lookupData.filter(model => model.code === 'PP1')[0].code);
    }
    
    let requestBody = {
        mmEnabled: e.target.checked,
        modelCode: e.target.checked ? 'OPENAI1' : 'PP1'
    }
    props.updateMultimodalSettings(props.selectedCustomer?.id, requestBody);
  }

  const handlemodelCodeChange = (e) => {
    setmodelCode(e.target.value);
  }

  const handleOnDemoModeEnabledChange = (e) => {
    setDemomodeEnabled(e.target.checked);
    let requestBody = {
      demomodeEnabled: e.target.checked
    }

    props.updateDemoModeSettings(props.selectedCustomer?.id, requestBody);
  }
  
  const handleOnPPEnabledChange = (e) => {
    setProcessPageEnabled(e.target.checked);
    let requestBody = {
      ppEnabled: e.target.checked
    }
    props.updateProcessPageSettings(props.selectedCustomer?.id, requestBody);
  }

  return (
   
    <div className={`mt-3`}>
      <>
        <div className={`${Style.profileFontStyle}`}>
               Scan Settings
            </div>
        <Card className="" style={{width: "641px", minHeight: "500px"}}>
        {
      props.settingsLoading ?
      <LoaderWrapper loading={props.settingsLoading} waitForData={false} isFlex={true} styles={{top: '100px'}}></LoaderWrapper>
      :
            <Card.Body>
              <Form>
                <Form.Group className={`mb-3 ${Style.setting}`} controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '20px' }}>Demo Mode</Form.Label>
                    
                  <Form.Check
                    type="switch"
                    id="demo-switch"
                    label=""
                    checked={!!demomodeEnabled} //!! is to convert the value to boolean
                    onChange={(e) => handleOnDemoModeEnabledChange(e)}
                    
                  />
                </Form.Group>
              </Form>

              <Form>
                <Form.Group className={`mb-3 ${Style.setting}`} controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '20px' }}>Process Page</Form.Label>
                    
                  <Form.Check
                    type="switch"
                    id="pp-switch"
                    label=""
                    checked={!!ppEnabled}
                    onChange={(e) => handleOnPPEnabledChange(e)}
                    
                  />
                </Form.Group>
              </Form>

              <Form>
                <Form.Group className={`mb-3 ${Style.setting}`} controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '20px' }}>Multimodal</Form.Label>
                    
                  <Form.Check
                    type="switch"
                    id="mm-switch"
                    label=""
                    checked={!!mmEnabled}
                    onChange={(e) => handleOnmmEnabledChange(e)}
                    
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control as="select"name="modelCode"onChange={(e) => handlemodelCodeChange(e)} value={modelCode} disabled={true}>
                        {
                            props.lookupData.map((model) => (
                                <option key={model.organizationId} value={model.code}>{model.value}</option>
                            ))
                        }
                        </Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
        }
        </Card>
        </>  
    </div>
  );
};

const mapStateToProps = (state) => ({
    loading: state.entities.customers.loading,
    selectedCustomer: state.entities.customers.selected,
    settingsLoading : state.entities.customers.scanConfigSettingsLoading,
    mmEnabled : state.entities.customers.scanConfigSettings.mmEnabled,
    modelCode : state.entities.customers.scanConfigSettings.modelCode,
    demomodeEnabled : state.entities.customers.scanConfigSettings.demomodeEnabled,
    ppEnabled : state.entities.customers.scanConfigSettings.ppEnabled,
    lookupData: state.entities.lookup.data

});

const mapDispatchToProps = (dispatch) => ({
    getMultimodalSettings: (customerId) => dispatch(getMultimodalSettings(customerId)),
    updateMultimodalSettings: (customerId, body) => dispatch(updateMultimodalSettings(customerId, body)),
    getDemoModeSettings: (customerId) => dispatch(getDemoModeSettings(customerId)),
    updateDemoModeSettings: (customerId, body) => dispatch(updateDemoModeSettings(customerId, body)),
    getProcessPageSettings: (customerId) => dispatch(getProcessPageSettings(customerId)),
    updateProcessPageSettings: (customerId, body) => dispatch(updateProcessPageSettings(customerId, body)),
    getLookupData: () => dispatch(getLookupData())

})  

export default connect(mapStateToProps, mapDispatchToProps)(ScanSettings);
