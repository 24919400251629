import React, { Component } from 'react';
import Style from '../../licenseManagement.module.css';
import Tooltip from '../popover/tooltip';
import Pagination from '../paginator/pagination';
import { connect } from 'react-redux';
import { formatDetectionDatetime } from '../../../utilities/datetime';
import { v4 as uuidv4 } from 'uuid';
import LoaderWrapper from '../LoaderWrapper/LoaderWrapper';

let _DEFAULT_GRID_DEFINITION = {
    name: 'defaultGrid',
    columns: []
}

let _DEFAULT_GRID_PAGE_SIZE = 20;
  

class GridComponent extends Component {
    state = {
        data: this.props.data || [],
        pageSize: this.props.pageSize || _DEFAULT_GRID_PAGE_SIZE,
        def: this.props.definition || _DEFAULT_GRID_DEFINITION,
        isLastPage: typeof this.props.isLastPage === "boolean" ? this.props.isLastPage : false,
        resetOnChange: this.props.resetOnChange || `id-${uuidv4()}`
    }

    getNextPageData = (pageNo, pageSize) => {
        if(typeof this.props.getNextPageData === "function") {
            this.props.getNextPageData(pageNo, pageSize);
        }
    }

    getPrevPageData = (pageNo, pageSize) => {
        if(typeof this.props.getPrevPageData === "function") {
            this.props.getPrevPageData(pageNo, pageSize);
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.resetOnChange !== this.props.resetOnChange) {
            this.setState({resetOnChange: this.props.resetOnChange}, () => {
                if(typeof this.props.getNextPageData === "function") {
                    this.getNextPageData(1, this.state.pageSize);
                }}
            );
        }
        if(prevProps.data !== this.props.data) {
            this.setState({data: this.props.data, isLastPage: this.props.data.length < this.state.pageSize ? true : false});
        }
        if(prevProps.pageSize !== this.props.pageSize) {
            this.setState({pageSize: this.props.pageSize});
        }
        if(prevProps.definition !== this.props.definition) {
            this.setState({def: this.props.definition});
        }
    }

    componentDidMount() {
        // first time load first page data
        if(typeof this.props.getNextPageData === "function" && this.props.selectedCustomer && this.props.selectedCustomer.id === this.props.params) {
            this.props.getNextPageData(1, this.state.pageSize);
        }

    }

    render() {
        return (
            <div className="grid">
            <div className="scrollX">
                <table id={this.state.def.name+'_tableId'} className="listTable mt-3">
                    <thead className="tableHead">
                        <tr>{this.state.def.columns.map((column, index) => {
                                return (
                                    <th key={index} style={{ minWidth: column.width, width: column.width }}>{column.displayName}</th>
                                )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                {
                    this.props.loaderFlag ?
                    <tr><td colSpan={this.state.def.columns.length}>
                        <LoaderWrapper loading={this.props.loaderFlag}  data={this.state.data} waitForData={true} styles={{ minHeight: '380px' }} isFlex={true} placeholderText={"No data available"} >
                            </LoaderWrapper>
                    </td></tr>
                    :
                    this.state.data.length === 0 ?
                    (<tr><td colSpan={this.state.def.columns.length}>
                            <div className={"notFound"} style={{ fontSize: '18px', paddingTop:'20px'}}>
                                <p className={` `}>No Records Found</p>
                            </div>
                        </td>
                    </tr>
                    )
                    :
                    <>
                    {this.state.data
                    .map((item, rowindx) => {
                        if(rowindx  < this.state.pageSize - 1){
                            return (
                                <tr id={this.state.def.name +'_trid' + '_' + rowindx} key={rowindx}>
                                    { this.state.def.columns.map((column, colindx) => {
                                        return (
                                            <td id={this.state.def.name + '_trid' + rowindx + '_tdid' + '_' + colindx} key={colindx} className={typeof column["className"] === "string" ? column["className"] : ''} onClick={typeof this.props.rowClick === "function" ? ()=> { this.props.rowClick(item)} : ()=>{} }>
                                                {
                                                    (typeof column["dataType"] === "string" && column["dataType"] === "image" && typeof column["src"] === "string") ?
                                                        <img onClick={typeof column["onClick"] === "function" ? ()=>{column["onClick"](item)} : ()=>{}} className={Style.pointer} src={column["src"]} />
                                                        :<div>
                                                        <div className={`${Style.nameWrap}`} onClick={typeof column["onClick"] === "function" ? ()=>{column["onClick"](item)} : ()=>{}} data-tip data-for={this.state.def.name + '_trid' + rowindx + '_tdid' + '_' + colindx + '_tip'} > 
                                                            
                                                            { typeof column["prefix"] === "string" ? column["prefix"] + ' ' : '' }
                                                            { typeof column["dataType"] === "string" && typeof column["subType"] === "string" && column["dataType"] === "date" && column["subType"] === "datetime" ? formatDetectionDatetime(item[column.fieldName]) : (column["dataType"] === "array" && column["subType"] === "string-array") ? item[column.fieldName].join(", ") : item[column.fieldName] } 
                                                            { typeof column["dataType"] === "string" && column["dataType"] === "boolean" ? item[column.fieldName] === true ? column["trueValue"] : column["falseValue"] : '' }
                                                        </div>
                                                        <Tooltip id={this.state.def.name + '_trid' + rowindx + '_tdid' + '_' + colindx + '_tip'} data={typeof column["dataType"] === "string" && typeof column["subType"] === "string" && column["dataType"] === "date" && column["subType"] === "datetime" ? formatDetectionDatetime(item[column.fieldName]) : (column["dataType"] === "array" && column["subType"] === "string-array") ? item[column.fieldName].join(", ") : column["dataType"] === "boolean" ? item[column.fieldName] === true ? column["trueValue"] : column["falseValue"] : item[column.fieldName]} />
                                                    </div>
                                                }
                                            </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        }
                    })}
                   
                    </>
                }
                 </tbody>
                </table>
                
            </div>
            <Pagination resetOnChange={this.state.resetOnChange} isLastPage={this.state.isLastPage} pageSize={this.state.pageSize} getPrevPageData={this.getPrevPageData} getNextPageData={this.getNextPageData} loaderFlag={this.props.loaderFlag != undefined ? this.props.loaderFlag : false} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(GridComponent);
