import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Tab, Tabs } from 'react-bootstrap'

import { getCustomerDetails, setCurrentCustomer } from '../store/entities/customers'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'
import ModalPopup from '../components/common/ModalPopup'

import DeploymentAutomatic from './deploymentAutomatic'
import DeploymentManual from './deploymentManual'
import Style from './navbarStyles.module.css'

class Deployment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedNavItem: 'Automatic',
    }
  }
  componentDidMount = () => {
    let { selectedCustomer } = this.props

    if (selectedCustomer) {
            this.props.getCustomerDetails(selectedCustomer.id)
    }
  }

  componentDidUpdate = (prevProps) => {
    let { selectedCustomer } = this.props
    
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
            this.props.getCustomerDetails(selectedCustomer.id)
    }
  }

  handleSelectNavItem = (item) => {
    this.setState({
      selectedNavItem: item,
    })
  }

  render() {
    let { selectedNavItem } = this.state
    return (
      <>
        <LoaderWrapper loading={!this.props.customerDetails} data={this.props.customerDetails} styles={{minHeight: '600px'}}>
        { this.props.customerDetails && <>
            <div className={`${Style.navbar} d-flex`}>
              <div
                onClick={() => this.handleSelectNavItem('Automatic')}
                className={
                  `${Style.navbarFontStyle} ` +
                  `${
                    selectedNavItem === 'Automatic'
                      ? Style.navbarSelectedItemStyle
                      : ''
                  } d-flex align-items-center`
                }
              >
                {'Automatic'}
              </div>

              <div
                onClick={(e) => this.handleSelectNavItem('Manual')}
                className={
                  `${Style.navbarFontStyle} ` +
                  `${
                    selectedNavItem === 'Manual'
                      ? Style.navbarSelectedItemStyle
                      : ''
                  } d-flex align-items-center`
                }
              >
                {'Manual'}
              </div>
            </div>
            <div
              className={`Container`}
              style={{
                padding: '0 1.8% 0 2.4%',
                margin: '0 0 0 43px',
              }}
            >
              {selectedNavItem == 'Automatic' ? (
                <DeploymentAutomatic
                  msiUrl={this.props.customerDetails.msiUrl}
                  licenseKey={this.props.customerDetails.licenseKey}
                  currentSkin={this.props.currentSkin}
                  supportEmailDeployment={this.props.selectedCustomer.supportEmailDeployment}
                />
              ) : (
                <DeploymentManual {...this.props} />
              )}
            </div>
          </>}
        </LoaderWrapper>
        {this.props.statusCode && (
          <ModalPopup
            statusCode={this.props.statusCode}
            msg={this.props.userMsg}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.entities.customers.selected,
  customerDetails: state.entities.customers.customerDetails,
  userMsg: state.entities.customers.userMsg,
  statusCode: state.entities.customers.statusCode,
  loading: state.entities.customers.loading,
  })

const mapDispatchToProps = (dispatch) => ({
  getCustomerDetails: (customerId) => dispatch(getCustomerDetails(customerId)),
  })

export default connect(mapStateToProps, mapDispatchToProps)(Deployment)
