import { createSlice } from '@reduxjs/toolkit'
// import { toast } from 'react-toastify'

import { cacheCallBegan } from '../cache'
import { eventsInitialState as initialState } from '../initialState/eventsState'
import { apiCallBegan } from '../api'
import { filtersOnUpdate } from '../filters'

let _totalRecords = 0

const charts = {
  THREADS_DETECTED: "threatDetected",
  TOP_THREATS: "topthreats",
  TOP_VULNERABLE: "topvulnerable",
}

const eventsFailed = (events, action) => {
  if(action && action.payload && action.payload === "Invalid customer id") {
    events.loading = false;
    events.userMsg = null
    events.statusCode = null  
  } else {
    events.loading = false;
    events.userMsg = "could not load events!!"
    events.statusCode = 500  
  }
}

const slice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    onSuccess: (events, action)=> {
      events.filters = action.payload.filters;
    },
    onThreatFiltersSuccess: (events, action)=> {
      events.threat.filters = action.payload.filters;
    },
    onChartFiltersSuccess: (events, action)=> {
      events.chart.filters = action.payload.filters;
    },
    eventsRequested: (events) => {
      events.loading = true
    },
    threatsDetectedRequested: (events) => {
      events[`${charts.THREADS_DETECTED}Loading`] = true
    },
    topThreatsRequested: (events) => {
      events[`${charts.TOP_THREATS}Loading`] = true
    },
    topVulnerableRequested: (events) => {
      events[`${charts.TOP_VULNERABLE}Loading`] = true
    },
    // TODO: Analyze the impact and combine eventsReceived & nexteventsReceived reducers
    eventsReceived: (events, action) => {
      const { records, filters, totalRecords } = action.payload.response
      const mappedRecords = records.map(record => {
        if (record.userAction === "Pending") {
          let dt = new Date(record.detectionTime);
          dt.setMinutes(dt.getMinutes() + 5)
          if (new Date() >= dt)
            record.userAction = "Close";
        }
        record = setEventStatus(record);
        return record;
      })
      mappedRecords.splice(20,1)
      events.list = mappedRecords
      events.totalSize = records.length
      _totalRecords = records.length
      events.loading = false
      events.switchToChart = events.switchToChartOnLogin && mappedRecords.length <= 0 ;
      events.switchToChartOnLogin = false
    },

    threatsDetectedRecieved: (events, action) => {
      const { data , errors} = action.payload.response;
      if(errors.length > 0){
        events.userMsg = errors[0].desc; 
        events.statusCode = 500;
      }

      events[charts.THREADS_DETECTED] = data ? data : [];
      events.loading = false
      events[`${charts.THREADS_DETECTED}Loading`] = false
    },

    topThreatsRecieved: (events, action) => {
      const { data , errors} = action.payload.response;
      if(errors.length > 0){
        events.userMsg = errors[0].desc;
        events.statusCode = 500;
      }
      events[charts.TOP_THREATS] = data ? data : [];
      events.loading = false
      events[`${charts.TOP_THREATS}Loading`] = false
    },
    topVulnerableRecieved: (events, action) => {
      const { data , errors} = action.payload.response;
      if(errors.length > 0){
        events.userMsg = errors[0].desc;
        events.statusCode = 500;
      }
      events[charts.TOP_VULNERABLE] = data ? data : [];
      events.loading = false
      events[`${charts.TOP_VULNERABLE}Loading`] = false
    },

    loginPagesScannedEventsReceived: (events, action) => {
      const { data, errors } = action.payload.response;
      let records = (data && data.records && data.records.length > 0) ? data.records : [];
      //let filters = data.filters;
      let totalRecords = (data && data.totalRecords && records.length > 0) ? data.totalRecords : 0;
      
      const mappedRecords = records.map(record => {
        if (record.userAction === "Pending") {
          let dt = new Date(record.detectionTime);
          dt.setMinutes(dt.getMinutes() + 5)
          if (new Date() >= dt)
            record.userAction = "Close";
        }
        return record;
      })
      events.loginList = mappedRecords
      events.loginTotalSize = totalRecords
      events.loading = false
    },
    eventReceived: (events, action) => {
      events.loading = false
      events.selected = setEventStatus(action.payload.response);
    },
    setViewType: (events, action) => {
      events.threatView.currentView = action.payload
    },
    nexteventsReceived: (events, action) => {
      const { records, filters } = action.payload.response
      const mappedRecords = records.map(record => {
        if (record.userAction === "Pending") {
          let dt = new Date(record.detectionTime);
          dt.setMinutes(dt.getMinutes() + 5)
          if (new Date() >= dt)
            record.userAction = "Close";
        }
        record = setEventStatus(record);
        return record;
      })
      mappedRecords.splice(20,1)
      events.list = [...events.list, ...mappedRecords]
      events.totalSize = records.length
      events.loading = false
    },
    pervEventsReceived: (events, action) => {
      events.loading = false
      events.filters.offset = events.filters.offset - events.filters.pageSize
      events.totalSize = _totalRecords
    },
    eventsVerified: (events, action) => {
      const allEvents = action.payload.request.map((event) => event.eventId)
      if (events.filters.triagedEvent === 0) {
        events.list = events.list.filter(
          (event) => allEvents.indexOf(event.id) === -1,
        )
        events.list = events.list.map((event) => {
          event = setEventStatus(event);
          return event;
        });
        events.totalSize = events.totalSize - allEvents.length
      } else {
        events.list = events.list.map((event) => {
          if (allEvents.indexOf(event.id) !== -1) event.triagedEvent = true
          event = setEventStatus(event);
          return event
        })
      }

      // toast.success(action.payload.response)
      events.userMsg = action.payload.response
      events.statusCode = 200
      events.loading = false
    },
    eventsMarkedFalsePositive: (events, action) => {
      const allEvents = action.payload.request.map((event) => event.eventId)
      events.list = events.list.filter(
        (event) => allEvents.indexOf(event.id) === -1,
      )
      events.list = events.list.map((event) => {
        event = setEventStatus(event);
        return event;
      });
      events.totalSize = events.totalSize - allEvents.length
      // toast.success(action.payload.response)
      events.userMsg = action.payload.response
      events.statusCode = 200
      events.loading = false
    },

    // callEventsOnceIncidents : (events, action) => {
    //   events.isEventsLoadOnceIncidents = false
    // },

    eventsCleared: (events, action) => {
      const { eventId } = action.payload.request
      events.list = events.list.filter((event) => event.id !== eventId);
      events.list = events.list.map((event) => {
        event = setEventStatus(event);
        return event;
      });
      events.totalSize = events.totalSize - 1
      // toast.success(action.payload.response)
      events.userMsg = action.payload.response
      events.statusCode = 200
      events.loading = false
    },
    eventsRemoved: (events, action) => {
      const { eventId } = action.payload.request
      if (events.filters.triagedEvent === 1) {
        events.list = events.list.filter((event) => event.id !== eventId)
        events.list = events.list.map((event) => {
          event = setEventStatus(event);
          return event;
        });
        events.totalSize = events.totalSize - 1
      } else {
        events.list = events.list.map((event) => {
          if (event.id === eventId) event.triagedEvent = false;
          event = setEventStatus(event);
          return event
        })
      }
      // toast.success(action.payload.response)
      events.userMsg = action.payload.response
      events.statusCode = 200
      events.loading = false
    },
    eventsRequestFailed: (events, action) => {
      eventsFailed(events, action)
    },
    threatsDetectedRequestFailed: (events, action) => {
      eventsFailed(events, action)
      events[`${charts.THREADS_DETECTED}Loading`] = false
    },
    topThreatsRequestFailed: (events, action) => {
      eventsFailed(events, action)
      events[`${charts.TOP_THREATS}Loading`] = false
    },
    topVulnerableRequestFailed: (events, action) => {
      eventsFailed(events, action)
      events[`${charts.TOP_VULNERABLE}Loading`] = false
    },
    cacheHit: (events, action) => {
      events.threat.filters.offset = action.payload.data.offset
      events.loading = false
    },
    eventsClearedAll: (events, action) => {
      events.list = initialState.list;
      events.totalSize = initialState.totalSize;
      events.loading = initialState.loading;
      events.selected = initialState.selected;
      delete events.filters.triagedEvent;
      delete events.filters.brand;
      delete events.filters.extensionUserId;
      events.filters.order = initialState.filters.order;
      events.filters.type = initialState.filters.type;
      events.filters.offset = initialState.filters.offset;
      events.filters.cleared = initialState.filters.cleared;
      events.filters.pageSize = initialState.filters.pageSize;
      events.filters.startTime = initialState.filters.startTime;
      events.filters.endTime = initialState.filters.endTime;
    },
    // hitClearEvent: (events, action) => {
    //   events.list = []
    //   events.isEventsLoadOnceIncidents = true
    // },
    resetModalSuccess: (events) => {
      events.statusCode = null
    },
    setSwitchToChart : (events, action) => {
      events.switchToChartOnLogin = false
      events.switchToChart = false
    }
  },
})

export const {
  eventsReceived,
  threatsDetectedRecieved,
  loginPagesScannedEventsReceived,
  topThreatsRecieved,
  topVulnerableRecieved,
  // callEventsOnceIncidents,
  resetModalSuccess,
  eventReceived,
  setViewType,
  nexteventsReceived,
  eventsRequested,
  threatsDetectedRequested,
  topThreatsRequested,
  topVulnerableRequested,
  pervEventsReceived,
  eventsVerified,
  eventsMarkedFalsePositive,
  eventsCleared,
  eventsRemoved,
  eventsRequestFailed,
  threatsDetectedRequestFailed,
  topThreatsRequestFailed,
  topVulnerableRequestFailed,
  cacheHit,
  eventsClearedAll,
  // hitClearEvent,
  onSuccess,
  onChartFiltersSuccess,
  onThreatFiltersSuccess,
  setSwitchToChart
} = slice.actions

export default slice.reducer;

export const setFilterInStore=(filters)=> {  
  return filtersOnUpdate({filters,
    onSuccess: onSuccess.type
  });
}
export const setThreatFiltersInStore=(filters)=>{
  return filtersOnUpdate({filters,
    onSuccess: onThreatFiltersSuccess.type
  });
}
export const setChartFilterInStore=(filters)=> {
  return filtersOnUpdate({filters,
    onSuccess: onChartFiltersSuccess.type
  });
}
export const loadEvents = (filters, selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
 
  // const selectedCustomer = getCustomersUnderTA(getState)
  // console.log("defaultCustomerId", selectedCustomer.defaultCustomerId)
  // console.log("[LoadEvents] customers:", customersUnderTA[1]?.id)
  const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/all-events`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: eventsRequested.type,
      onSuccess: eventsReceived.type,
      onError: eventsRequestFailed.type,
    }))
  }
}

export const loadThreatsDetected = (filters, selectedId, defaultCustomerId, loadingChart) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadThreatsDetected] customers:", customersUnderTA[1]?.id)
    const url = `dashboard/stats/${selectedId}/threats`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: filters?.r == 'M' ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: threatsDetectedRequested.type,
      onSuccess: threatsDetectedRecieved.type,
      onError: threatsDetectedRequestFailed.type,
    }))
  }
}
export const loadTopThreats = (filters, selectedId, defaultCustomerId) => {
    return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadTopThreats] customers:", customersUnderTA[1]?.id)
    const url = `dashboard/stats/${selectedId}/threats`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: filters?.r == 'M' ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: topThreatsRequested.type,
      onSuccess: topThreatsRecieved.type,
      onError: topThreatsRequestFailed.type,
    }))
  }
}

export const loadTopVulnerable = (filters, selectedId, defaultCustomerId) => {
    return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadTopVulnerable] customers:", customersUnderTA[1]?.id)
    const url = `dashboard/stats/${selectedId}/threats`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: filters?.r == 'M' ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: topVulnerableRequested.type,
      onSuccess: topVulnerableRecieved.type,
      onError: topVulnerableRequestFailed.type,
    }))
  }
}

export const loadLoginPagesScannedEvents = (filters, selectedId, defaultCustomerId) => {
  return (dispatch, getState) => {
    // const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadPrevEvents] customers:", customersUnderTA[1]?.id)
    const url = `dashboard/stats/${selectedId}/events`;
    return dispatch(apiCallBegan({
      urlPath: url,
      method: 'GET',
      filters: filters?.r == 'M' ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: eventsRequested.type,
      onSuccess: loginPagesScannedEventsReceived.type,
      onError: eventsRequestFailed.type,
    }))
  }
}

export const loadNextEvents = (filters, selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) =>{
    const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadNextEvents] customers:", customersUnderTA[1]?.id)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/all-events`;
    return dispatch(cacheCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: eventsRequested.type,
      onSuccess: nexteventsReceived.type,
      onError: eventsRequestFailed.type,
      onHit: cacheHit.type,
    }))
  }
}

// export const incidentsLoadEvents = () => {
//   return callEventsOnceIncidents();
// }

export const loadPrevEvents = (filters, selectedId, defaultCustomerId, isGlobalView) => {
  return (dispatch, getState) => {
    const customersUnderTA = getCustomersUnderTA(getState)
    // console.log("[loadPrevEvents] customers:", customersUnderTA[1]?.id)
    const url = `portal/${isGlobalView ? 'tenants' : 'customers'}/${selectedId}/all-events`;
    return dispatch(cacheCallBegan({
      urlPath: url,
      method: 'GET',
      filters: isGlobalView ? {...filters, customerId: defaultCustomerId} : filters,
      onStart: eventsRequested.type,
      onSuccess: pervEventsReceived.type,
      onError: eventsRequestFailed.type,
      onHit: cacheHit.type,
    }))
  }
}

export const loadEventById = (eventId, customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/events/${eventId}`,
    method: 'GET',
    onStart: eventsRequested.type,
    onSuccess: eventReceived.type,
    onError: eventsRequestFailed.type,
  })
}

export const verifyEvents = (body, customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/events/${body[0]['eventId']}/verify-events`,
    data: body,
    method: 'POST',
    onStart: eventsRequested.type,
    onSuccess: eventsVerified.type,
    onError: eventsRequestFailed.type,
  })
}

export const markFalsePositive = (body, customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/events/${body[0]['eventId']}/report-false-positive`,
    data: body,
    method: 'POST',
    onStart: eventsRequested.type,
    onSuccess: eventsMarkedFalsePositive.type,
    onError: eventsRequestFailed.type,
  })
}

export const clearIncident = (body, customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/events/${body['eventId']}/clear-incident`,
    data: body,
    method: 'POST',
    onStart: eventsRequested.type,
    onSuccess: eventsCleared.type,
    onError: eventsRequestFailed.type,
  })
}

export const removeIncident = (body, customerId) => {
  return apiCallBegan({
    urlPath: `portal/customers/${customerId}/events/${body['eventId']}/remove-threat`,
    data: body,
    method: 'PUT',
    onStart: eventsRequested.type,
    onSuccess: eventsRemoved.type,
    onError: eventsRequestFailed.type,
  })
}

export const clearAllEvents = () => {
  return eventsClearedAll();
}

// export const clearEvents = () => {
//   return hitClearEvent();
// }

export const resetEventsModal = () => {
  return resetModalSuccess()
}

export const setSwitchToChartEvents = () => {
  return setSwitchToChart();
}

const getCustomersUnderTA = (getState) => {
    const currentState = getState();
    const currentList = currentState.entities.customers.list;
    // console.log("[getBrands] useers", currentList);
    // console.log("CustomerId:", currentList[1]?.id);
    return currentList;
}

const setEventStatus = (event) => {
  if(!event.status)
    event.status = JSON.parse(event.triagedEvent) == true ? "Verified Phish" : event.type == 3 ? "Reported Phish" : "Suspicious"
  return event;
}