import React from "react";
import { useState, useEffect } from 'react';

function SearchBox(props) {

  const [srchTxt, setSrchTxt] = useState('');
  return (
    <div style={{width: props.width || "100%", height: props.height || "100%"}} >
      <input
        type="search"
        name="search-form"
        id="search-form"
        className="search-input"
        onChange={(e) => props.handleSearch(e.target.value)}
        placeholder={props.placeholder || "Search here"}
        style={{width: props.width || "100%", height: props.height || "100%"}}
      />
    </div>
  );
}

export default SearchBox;