import React from 'react';
import { useState, useEffect } from 'react';
import GridComponent from './common/grid/GridComponent';
import Style from './AdvancedSettings.module.css';
import { connect } from 'react-redux';
import SearchBox from './common/searchBox/searchBox';
import { getBrandPolicies, getBrandPoliciesTotal } from '../store/entities/customers';
import { withRouter } from 'react-router-dom';
import updateSvg from '../assets/update.svg'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';

const BrandPolicies = (props) => {

    const [srchTxt, setSrchTxt] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [offset, setOffset] = useState(0);
    const [pageSize, setPageSize] = useState(11); 
    const [resetOnChange, setResetOnChange] = useState(false);
    let {selectedCustomer} = props;
    useEffect(() => {
        //fetch brandpolicies
        props.getBrandPoliciesTotal(); //fetch total brandpolicies
    }, [selectedCustomer]);

    useEffect(() => {
    //fetch BrandPolcies when filter changes
    props.getBrandPolicies({isActive: isActive, srchTxt: srchTxt, offset : offset, pageSize: pageSize});
    }, [selectedCustomer, isActive, offset, srchTxt]);

    // useEffect(() => {
    //     // Debounce logic
    //     const timeoutId = setTimeout(() => {
    //         props.getBrandPolicies({isActive: isActive, srchTxt: srchTxt, offset : offset, pageSize: pageSize});
    //     }, 500); // Delay of 500ms
    //   return () => clearTimeout(timeoutId);
    // },[srchTxt])

    const handleSearch = (value) => {
        setSrchTxt(value);
        setOffset(0);
        // setPageSize(10);
        setResetOnChange(!resetOnChange);
    }

    const handleIsActive = (e) => {
        setIsActive(e.target.checked);
        setOffset(0);
        // setPageSize(10);
        setResetOnChange(!resetOnChange);
    }

    const handleAdd = () => {
        props.history.push({pathname: `/advanced-settings/add-edit-brandpolicy`, state: {brandPolicy : null, mode : 'add'}});
    }

    const handleEdit = (brandPolicy) => {
        props.history.push({pathname: `/advanced-settings/add-edit-brandpolicy`, state: {brandPolicy, mode : 'edit'}});
    }

    const getNextPageData = (pageNo, pageSize) => {
        setData(pageNo, pageSize);
    }
    
    const getPrevPageData = (pageNo, pageSize) => {
        setData(pageNo, pageSize);
    }
    
    const setData = (pageNo, pageSize) => {
        const start = ((pageNo - 1) * (pageSize - 1));
        setOffset(start);
    }
      const policiesGridDef = {
        name: 'policiesGrid',
        columns: [
            {fieldName: 'brandName', displayName: 'Brand Name', dataType : 'string', width: '20%', className: Style.boldFont},
            {fieldName: 'alias', displayName: 'Brand Alias', dataType: 'array', subType: 'string-array', width: '20%', className: Style.italicFont},
            {fieldName: 'domain', displayName: 'Brand Domain', dataType: 'array', subType: 'string-array', width: '20%', className: Style.italicFont},
            {fieldName: 'isActive', displayName: 'Active', dataType: 'boolean', trueValue : "Yes", falseValue : "No", width: '20%'},
            {fieldName: 'action', displayName: 'Action', dataType: 'image', src: updateSvg, width: '20%', onClick: handleEdit}
        ]
      }
    return (
        <>
            <div className={'w-100'}>
                <div className={`${Style.titleStyle}`}>
                    {props.title}
                </div>
                <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-2 px-5`}>
                    <div style={{width: '80%'}} className={`${Style.borderStyle} px-4 mt-2`}>
                        <div className={`${Style.fontBold}`}>
                            Total Brands
                        </div>
                        {
                            (props.brandPoliciesTotalLoading) ?
                                <LoaderWrapper loading={props.brandPoliciesTotalLoading} waitForData={false} styles={{ minHeight: '60px', width: '100px'}} isFlex={true} >
                                </LoaderWrapper>
                            :
                                <div className={`${Style.currentAdminCount}`}>
                                    {props.brandPoliciesTotal}
                                </div>                                
                        }
                        {/* <LoaderWrapper loading={props.brandPoliciesTotalLoading} waitForData={false} styles={{ minHeight: '60px'}} isFlex={true} >
                        </LoaderWrapper>
                        <div className={`${Style.currentAdminCount}`}>
                        {props.brandPoliciesTotal}
                        </div> */}
                    </div>
                    <div style={{width: '20%'}} className={'ml-4'}>
                        <div className={`${Style.fontBold}`}>
                            Add Brand Policy
                        </div>
                        <div className={'d-flex mt-3'}>
                            <button className={`${Style.submitButton} focus-none px-3`} onClick={handleAdd}>+ Add Brand Policy</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Style.brandpolicyContainer}>
                <div className={`${Style.policiesFilters} mx-2 my-3` }>
                    <div>Filter</div>
                    <div id={Style.searchBox} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#ffffff'}}> 
                        <div style={{width: '70%', display: 'flex', alignItems: 'center', borderRight: 'solid 1px #e3e3e3'}}>
                            <div className="d-flex" style={{height: '40px', width: '60%'}}>
                                <SearchBox style={{width: '100%'}}
                                    placeholder="Search Brand, Alias, Domain"
                                    value={srchTxt}
                                    handleSearch={handleSearch}
                                />
                            </div>
                            <div style={{width: '40%', display: 'flex', alignItems: 'center', paddingLeft: '30px'}}>
                                <span className='px-2'>
                                    <input type="checkbox" id="isActive" name="isActive-check" onChange={handleIsActive} checked={isActive} style={{paddingLeft : "5px"}}></input>
                                </span>
                                <span>Active</span>
                            </div>
                        </div>
                        <div id="lblSearchResultCount" className="mx-2" style={{width: '30%', textAlign: 'right'}}>
                            <span className='px-3'>{props.brandPoliciesTotalRec ? props.brandPoliciesTotalRec : 'No'} Results Found </span>
                        </div>
                    </div>
                </div>
                <GridComponent loaderFlag={props.brandPoliciesLoading} resetOnChange={resetOnChange} data={props.brandPolicies} pageSize={pageSize} definition={policiesGridDef} getPrevPageData={getPrevPageData} getNextPageData={getNextPageData} params={""} rowClick={() => {}} />
            </div>
        </>
    )
}

    

const mapStateToProps = (state) => ({
    brandPoliciesTotalLoading : state.entities.customers.brandPoliciesTotalLoading,
    brandPoliciesTotal : state.entities.customers.brandPoliciesTotal,
    brandPolicies : state.entities.customers.brandPolicies,
    brandPoliciesLoading : state.entities.customers.brandPoliciesLoading,
    brandPoliciesTotalRec : state.entities.customers.brandPoliciesTotalRec,
    selectedCustomer: state.entities.customers.selected,
    customerLoading: state.entities.customers.loading,
})

const mapDispatchToProps = (dispatch) => ({
    getBrandPolicies : (body) => dispatch(getBrandPolicies(body)),
    getBrandPoliciesTotal : (body) => dispatch(getBrandPoliciesTotal(body)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandPolicies));