import React, { Component,} from 'react';
import { connect } from 'react-redux';
import {
    getCustomerAlertConfig,
    updateCustomerAlertConfig,
} from '../store/entities/customers';
import NavbarStyle from './navbarStyles.module.css';
import ExtensionSettings from './ExtensionSettings';
import CreateCustomer from './CreateCustomer';
import ScanSettings from './ScanSettings';
import GoogleFormSettings from './GoogleFormSettings';
import BrandPolicies from './BrandPolicies';

class AdvancedSettings extends Component {
  
    constructor(props) {
        super(props)
        this.state = {
            selectedNavItem: this.props.location.hasOwnProperty('state') ? this.props.location.state !== undefined ?  this.props.location.state.tab : "Extension Settings" : "Extension Settings",
        };
    }

componentDidMount() {
}

componentDidUpdate(prevProps) {
  
}

handleSelectNavItem = (item) => {
    this.setState({
      selectedNavItem: item,
    })
  }

  
  renderTab = () => {
    let { selectedNavItem } = this.state
    switch (selectedNavItem) {
      case 'Extension Settings':
        return <ExtensionSettings />
      case 'Create Customer':
        return <CreateCustomer />
      case 'Scan Settings':
        return <ScanSettings />
      case 'Google Form Settings':
        return <GoogleFormSettings />
      case 'Baseline Brand Policies':
        return <BrandPolicies title="Baseline Brand Policies" />
      default:
    }
  }
render(){
    let { selectedNavItem } = this.state
  return (
    this.props.userRole && this.props.userRole != 'PixmAdmin' 
    ? <div id="not-found"><h3 style={{textAlign : "center"}}>You are not authorized to view this page.</h3></div>
    : (<div style={{height:"80vh"}}>
        <div className={`${NavbarStyle.navbar} pl-4 d-flex`}>
            <div
              onClick={() => this.handleSelectNavItem('Extension Settings')}
              className={
                `${NavbarStyle.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Extension Settings'
                    ? NavbarStyle.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Extension Settings
            </div>
            <div
              onClick={() => this.handleSelectNavItem('Create Customer')}
              className={
                `${NavbarStyle.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Create Customer'
                    ? NavbarStyle.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Create Customer
            </div>
            <div
              onClick={() => this.handleSelectNavItem('Scan Settings')}
              className={
                `${NavbarStyle.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Scan Settings'
                    ? NavbarStyle.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Scan Settings
            </div>
            <div
              onClick={() => this.handleSelectNavItem('Google Form Settings')}
              className={
                `${NavbarStyle.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Google Form Settings'
                    ? NavbarStyle.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Google Form Settings
            </div>
            <div
              onClick={() => this.handleSelectNavItem('Baseline Brand Policies')}
              className={
                `${NavbarStyle.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Baseline Brand Policies'
                    ? NavbarStyle.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Baseline Brand Policies
            </div>
        </div>
        <div className="pageContainer">{this.renderTab()}</div>
      </div>)
  )
}
}

const mapStateToProps = (state) => ({
    userRole: state.entities.user.roles,
    selectedCustomer: state.entities.customers.selected,
    alertConfig: state.entities.customers.alertConfig,
    statusCode : state.entities.customers.statusCode,
    userMsg : state.entities.customers.userMsg,
    loading : state.entities.customers.loading
})

const mapDispatchToProps = (dispatch) => ({
    getCustomerAlertConfig: (customerId) => dispatch(getCustomerAlertConfig(customerId)),
    updateCustomerAlertConfig: (customerId, data) => dispatch(updateCustomerAlertConfig(customerId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings)